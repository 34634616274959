<template>
    <div>
        <template v-if="loading">
            <b-row cols="1" cols-md="2" cols-lg="4">
                <b-col>
                    <SkeletonImgCard />
                </b-col>
                <b-col>
                    <SkeletonImgCard />
                </b-col>
                <b-col>
                    <SkeletonImgCard />
                </b-col>
                <b-col>
                    <SkeletonImgCard />
                </b-col>
            </b-row>
            <div class="w-25 m-auto">
                <b-skeleton animation="wave" />
            </div>
        </template>
        <template v-else>
            <b-carousel
            id="carousel-1"
            :interval="4000"
            controls
            indicators
            >
                <b-carousel-slide v-for="(chunk, ckey) in chunked" :key="ckey">
                    <template #img>
                        <b-row cols="1" cols-md="2" cols-lg="4">
                            <b-col v-for="(article, akey) in chunk" :key="akey">
                                <router-link :to="article.link">
                                    <b-card
                                        overlay
                                        no-body
                                        bg-variant="dark"
                                        text-variant="white"
                                        tag="article"
                                        class="mb-4"
                                    >
                                        <b-card-img-lazy height="200" :src="article.img" :alt="article.name" />
                                        <b-card-body overlay>
                                            <h3 class="card-title">{{ article.name }}</h3>
                                        </b-card-body>
                                    </b-card>
                                </router-link>
                            </b-col>
                        </b-row>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </template>
    </div>
</template>

<script>
    import SkeletonImgCard from '@/components/SkeletonImgCard'

    export default {
        components: {
            SkeletonImgCard
        },
        props: {
            city: String,
        },
        data() {
            return {
                loading: true,
                categories: []
            }
        },
        computed: {
            chunked () {
                return this.chunk(this.categories, 4)
            },
        },
        methods: {
            chunk (arr, len) {
                let chunks = []
                let i = 0
                let n = arr.length

                while (i < n) {
                    chunks.push(arr.slice(i, i += len))
                }

                return chunks
            },
            getCategories() {
                this.$axios.get("https://api.tixgo.nl/destination?filter[type]=categories&filter[city]=" + this.city)
                    .then( response => {
                        this.categories = response.data;
                        this.loading = false;
                    }
                ).catch((reason) => {
                    reason;
                });
            },
        },
        created () {
            this.getCategories();
        }
    }
</script>

<style scoped>
    .card-img-overlay{
        background-color:rgba(0,0,0,0.2);
    }
    .card-title{
        text-shadow: 1px 1px #000;
    }
</style>