<template>
    <div>
        <template v-if="loading">
            <b-row cols="1" cols-lg="3">
                <b-col>
                    <SkeletonCard />
                </b-col>
                <b-col>
                    <SkeletonCard />
                </b-col>
                <b-col>
                    <SkeletonCard />
                </b-col>
            </b-row>
            <div class="w-25 m-auto">
                <b-skeleton animation="wave" />
            </div>
        </template>
        <template v-else>
            <b-carousel
            id="carousel-1"
            :interval="0"
            controls
            indicators
            >
                <b-carousel-slide v-for="(chunk, ckey) in chunked" :key="ckey">
                    <template #img>
                        <b-row cols="1" cols-lg="3">
                            <b-col v-for="(event, akey) in chunk" :key="akey">
                                <b-card
                                    img-top
                                    no-body
                                    tag="article"
                                    class="mb-4"
                                >
                                    <b-card-img-lazy height="200" :src="`https://api.tixgo.nl/files/` + event.header_image + '/950'" :alt="event.name" />
                                    <b-card-body>
                                        <h4 class="card-title">{{ event.name }}</h4>
                                        <b-card-text>
                                            <div>{{ event.host.company_name }}</div>
                                            <span v-if="event.description" class="text-muted" v-html="$options.filters.truncate(event.description, 100)"></span>
                                        </b-card-text>
                                        <b-button :to="`/event/` + event.slug + `-` + event.slug_hash" variant="primary" class="stretched-link">
                                            Tickets
                                        </b-button>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                    </template>
                </b-carousel-slide>
            </b-carousel>
        </template>
    </div>
</template>

<script>
    import SkeletonCard from '@/components/SkeletonCard'

    export default {
        components: {
            SkeletonCard
        },
        props: {
            city: String,
        },
        data() {
            return {
                loading: true,
                listings: []
            }
        },
        computed: {
            chunked () {
                return this.chunk(this.listings, 3)
            },
        },
        methods: {
            chunk (arr, len) {
                let chunks = []
                let i = 0
                let n = arr.length

                while (i < n) {
                    chunks.push(arr.slice(i, i += len))
                }

                return chunks
            },
            getListings() {
                this.$axios.get("https://api.tixgo.nl/destination?filter[type]=popular&filter[city]=" + this.city + '&limit=9&random=true')
                    .then( response => {
                        this.listings = response.data;
                        this.loading = false;
                    }
                ).catch((reason) => {
                    reason;
                });
            },
        },
        created () {
            this.getListings();
        }
    }
</script>

<style scoped>
    .card-img-overlay{
        background-color:rgba(0,0,0,0.2);
    }
</style>